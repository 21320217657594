<template>
    <v-container style="max-width: 900px;" class="mx-auto">
        <div class="d-flex justify-center align-start mx-auto flex-wrap" style="gap: 16px;">
            <v-text-field 
                v-model="form.dui"
                v-mask="'########-#'"
                label="DUI"
                placeholder="Ingrese el DUI a buscar"
                hide-details="auto"
                outlined
                clearable
                :error-messages="duiErrors"
                :disabled="busquedaDocumento.isLoading"
            />
            <v-btn
                :disabled="!form.dui || !!duiErrors"
                class="flex-grow-1 flex-shrink-1 flex-md-grow-0"
                color="primary"
                x-large
                @click.stop="hacerNuevaBusqueda"
                :loading="busquedaDocumento.isLoading"
            >
                Buscar
            </v-btn>
        </div>
        <!-- Info -->
        <EmptyComponent 
            :empty="busquedaVacia"
            colored
            text="Ingrese un documento para buscar"
            class="mt-4"
        >
            <template v-slot:icon="{ className }">
                <v-icon :class="className">mdi-account-box</v-icon>
            </template>
        </EmptyComponent>
        <!-- Indicador de carga -->
        <SpinnerComponent :spinning="busquedaDocumento.isLoading" text="Buscando documento..." />
        <RetryDataLoading
            v-if="!busquedaVacia" 
            :retry-handler="reintentarBusqueda" 
            :loadable="busquedaDocumento"
            class="mt-4"
        >
            <div v-if="busquedaActual" class="d-flex align-center" style="gap: 8px;">
                <p class="text-uppercase font-weight-bold mb-0">Búsqueda actual: </p>
                <v-chip 
                    label 
                    color="#467dc6" 
                    text-color="#FFF !important"
                    close
                    @click:close="limpiarBusqueda"
                >
                    {{ busquedaActual}}
                </v-chip>
            </div>

            <div v-if="persona" class="mt-8">
                <div class="d-flex align-center" style="gap: 8px;">
                    <v-icon large>mdi-account-search</v-icon>
                    <h4 class="pb-0 mb-0 font-weight-bold text-uppercase text-h5">Coincidencia encontrada:</h4>
                </div>
                <v-divider />

                <v-card class="px-4 py-4 mt-4">
                    <div class="d-flex" style="gap: 16px;">
                        <span>
                            <img width="80" height="100" :src="`data:image/png;base64,${persona.foto}`" />
                        </span>
                        <v-row no-gutters>
                            <v-col cols="12" md="6">
                                <dl>
                                    <dt class="font-weight-bold">Nombre completo:</dt>
                                    <dd>{{ persona.nombre_completo }}</dd>
        
                                    <dt class="font-weight-bold mt-2">DUI:</dt>
                                    <dd>{{ persona.dui }}</dd>
        
                                    <dt class="font-weight-bold mt-2">Sexo:</dt>
                                    <dd>{{ persona.sexo === 'M' ? 'Masculino' : 'Femenino' }}</dd>
        
                                    <dt class="font-weight-bold mt-2">Pais:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.pais_domicilio) }}</dd>

                                    <dt class="font-weight-bold mt-2">Fecha de nacimiento:</dt>
                                    <dd>{{ formatDate(persona.fecha_nacimiento) }}</dd>

                                    <dt class="font-weight-bold mt-2">Nacionalidad:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.nacionalidad) }}</dd>

                                    <dt class="font-weight-bold mt-2">País de nacimiento:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.nombre_pais_nacimiento) }}</dd>

                                    <dt class="font-weight-bold mt-2">Departamento de nacimiento:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.nombre_departamento_nacimiento) }}</dd>

                                    <dt class="font-weight-bold mt-2">Municipio de nacimiento:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.nombre_municipio_nacimiento) }}</dd>

                                    <dt class="font-weight-bold mt-2">Nombre de la madre:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.nombre_madre) }}</dd>

                                    <dt class="font-weight-bold mt-2">Nombre del padre:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.nombre_padre) }}</dd>
                                </dl>
                            </v-col>
                            <v-col cols="12" md="6">
                                <dl>
                                    <dt class="font-weight-bold mt-2">Profesión:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.profesion) }}</dd>

                                    <dt class="font-weight-bold mt-2">Departamento:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.departamento_domicilio) }}</dd>
        
                                    <dt class="font-weight-bold mt-2">Municipio:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.municipio_domicilio) }}</dd>
        
                                    <dt class="font-weight-bold mt-2">Calle:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.calle) }}</dd>
        
                                    <dt class="font-weight-bold mt-2">Residencia:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.residencia) }}</dd>

                                    <dt class="font-weight-bold mt-2">Estado:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.estado) }}</dd>

                                    <dt class="font-weight-bold mt-2">Tipo trámite:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.tipo_tramite) }}</dd>

                                    <dt class="font-weight-bold mt-2">Estado familiar:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.estado_familiar) }}</dd>

                                    <dt class="font-weight-bold mt-2">Nombre cónyuge:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.nombre_conyuge) }}</dd>

                                    <dt class="font-weight-bold mt-2">Conocido por:</dt>
                                    <dd>{{ formatearDatoOpcional(persona.conocido_por) }}</dd>

                                    <dt class="font-weight-bold mt-2">Firma:</dt>
                                    <dd>
                                        <v-img v-if="persona.firma" :src="`data:image/png;base64,${persona.firma}`" max-width="100%" />
                                        <span>-</span>
                                    </dd>
                                    
                                </dl>
                            </v-col>
                        </v-row>
                    </div>

                    <v-card-actions class="justify-end">
                        <v-btn
                          color="secondary"
                          text
                          @click.stop="modalVerFotoAbierta = true"
                        >
                            <v-icon class="mr-1" color="secondary">mdi-eye</v-icon>
                            Ver foto
                        </v-btn>
                      </v-card-actions>
                </v-card>
            </div>
        </RetryDataLoading>
        <ModalVerFoto 
            :is-open="modalVerFotoAbierta"
            :persona="busquedaDocumento.data"
            @on-visibility-change="cerrarModalVerFoto"
        />
    </v-container>
</template>
<script>
import Vue from "vue"
import moment from "moment";
import { EmptyComponent, RetryDataLoading, SpinnerComponent } from "@/components/utils"
import { createLoadable, setLoadableResponse, toggleLoadable } from "@/utils/loadable";
import ModalVerFoto from "./ModalVerFoto.vue";

const duiRegex = /^(\d){8}-(\d){1}$/ig;
const duiRegexValidator = value => value ? duiRegex.test(value) || 'El formato del DUI no es válido.' : true;
const duiValidator = value => value ? Vue.prototype.isDui(value) || 'El DUI no es válido.' : true;

export default {
    components: { EmptyComponent, RetryDataLoading, SpinnerComponent, ModalVerFoto },
    data: () => ({
        form: {
            dui: null,
        },
        busquedaDocumento: createLoadable(null),
        busquedaActual: null,
        modalVerFotoAbierta: false,
    }),
    computed: {
        duiErrors() {
            const value = this.form.dui;
            const result = [duiRegexValidator(value), duiValidator(value)];
            const firstError = result.find((r) => typeof r === 'string');

            return firstError ?? null;
        },
        busquedaVacia() {
            return !this.busquedaDocumento.isDirty;       
        },
        persona() {
            return this.busquedaDocumento.data;
        },
    },
    methods: {
        async buscarDocumento(numeroDocumento) {
            toggleLoadable(this.busquedaDocumento);
            const { data } = await this.services.VerificarDocumento.buscarInformacionCompletaPorDocumento(numeroDocumento);
            setLoadableResponse(this.busquedaDocumento, data, { showAlertOnSuccess: true });
        },
        hacerNuevaBusqueda() {
            this.busquedaActual = this.form.dui;
            this.buscarDocumento(this.form.dui);
        },
        reintentarBusqueda() {
            this.buscarDocumento(this.busquedaActual);
        },
        limpiarBusqueda() {
            this.busquedaActual = null;
            this.busquedaDocumento = createLoadable(null);
        },
        cerrarModalVerFoto(visible) {
            this.modalVerFotoAbierta = visible
        },
        formatDate(fecha) {
            const fechaNacimiento = moment(fecha, "DD/MM/YYYY");

            return fechaNacimiento.isValid() ? fechaNacimiento.format("DD-MM-YYYY") : '-';
        },
        formatearDatoOpcional(dato) {
            return dato || '-';
        },
    }
}
</script>
<style scoped>
    :deep(.v-text-field--outlined>.v-input__control>.v-input__slot) {
        min-height: 52px !important;
    }

    :deep(.v-text-field--enclosed .v-input__append-inner) {
        margin-top: 15px !important;
    }

    :deep(.v-chip__close) {
        color: #FFF !important;
    }
</style>