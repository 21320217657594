<template>
    <v-dialog v-model="internalOpen" max-width="600px" persistent>
        <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative;">
            <div class="pt-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    rounded
                    text
                    @click.stop="cerrarModal"
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1">
                <img v-if="persona" width="400" height="500" :src="`data:image/png;base64,${persona.foto}`" class="d-block mx-auto" />
                <v-btn @click.stop="cerrarModal" class="d-block mx-auto mt-4" color="primary">Cerrar</v-btn>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { isNil } from 'lodash';

export default {
    name: 'ModalVerFoto',
    emits: ['on-visibility-change'],
    props: {
        isOpen: { type: Boolean },
        persona: { type: Object },
    },
    data: () => ({
        internalOpen: false,
    }),
    methods: {
        cerrarModal() {
            this.internalOpen = false;
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },    
}
</script>